import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import Image from 'gatsby-image/withIEPolyfill'
import React, { useContext, useEffect, useState } from 'react'

import GallerySection from '../../components/CarryAll/GallerySection'
import Layout from '../../components/Layout'
import PersonliseModal from '../../components/Personalisation'
import SelectorSection from '../../components/SelectorSection'
import SEO from '../../components/seo'
import StoreContext from '../../context/StoreContext'
import fbq from '../../helper/fpq'
import usePersonalisationParams from '../../helper/usePersonalisationParams'
import useProductData from '../../helper/useProductData'
import useSelectedColor from '../../helper/useSelectedColor'
import useTracking from '../../helper/useTracking'
import { CarryContainer } from '../../styles/suitcases'

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

const LandingContainer = styled.div`
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  @media (min-width: 763px) {
    flex-direction: row;
    padding: 64px 96px;
    padding-top: 64px;
    margin-top: 114px;
  }
`

const colorNamesText = [
  'Navy', //navy
  'Black', //charcoal
  'Forest', //forrest
  'Sand', //nude
  'Clay', //burgundy
  'Shadow', //monochrome
  'Yellow',
  'Blue',
  'Magenta',
]

const colorNamesTextParams = [
  'Navy',
  'Charcoal',
  'Forest',
  'Sand',
  'Clay',
  'Shadow',
  'Yellow',
  'Blue',
  'Magenta',
]

const count = ['2', '3']

const skus = [
  'CME21-NAV',
  'CME22-CHA',
  'CME23-FOR',
  'CME24-SAN',
  'CME25-CLA',
  'CME26-SHA',
  'CME27-YEL',
  'CME28-BLU',
]

const CarryMe = ({ data, pageContext, location }) => {
  const context = useContext(StoreContext)

  let [personaliseOpen, setPersonaliseOpen] = useState(false)
  let [labelData, setLabelData] = useState({})

  const personalised = isEmpty(labelData)

  const {
    title,
    price,
    hasPersonalisation,
    freePersonalisation,
    description,
    overview,
    specs,
    reviewId,
    shopifyIds,
    fbIDs,
    oosData,
  } = useProductData(data.strap, personalised)

  const [selectedColor, setSelectedColor] = useSelectedColor(
    location.search,
    0,
    colorNamesTextParams.map((val) => val.toLowerCase()),
    oosData
  )
  const [trackAddToCart, updateItem] = useTracking()

  const images_names = colorNamesText.map((color_val) =>
    count.map((count_val) => {
      return `${color_val}${count_val}`
    })
  )
  const images = images_names.map((second) => [
    ...second.map((name) => data[name]),
    data.Camp1,
    data.Camp2,
  ])

  useEffect(() => {
    updateItem({
      title: 'Carry Me',
      id: fbIDs[selectedColor] && fbIDs[selectedColor].split('_')[2],
      price: parseInt((price?.match(/\d+/g) || []).join('')),
      url: 'https://july.com/travel-bags/carry-me',
      imageUrl: '',
      sku: skus[selectedColor],
      fb_id: fbIDs[selectedColor],
      loaded: true,
      variant: colorNamesText[selectedColor],
    })
  }, [selectedColor, fbIDs])

  const handleAddToCart = async (e) => {
    e.preventDefault()

    let carryMeVarients = shopifyIds

    const backendLabelData = !personalised
      ? { ...labelData, font: labelData.font.toLowerCase() }
      : {}

    await context.addVariantToCart(
      [carryMeVarients[selectedColor]],
      1,
      {
        ...backendLabelData,
      },
      undefined,
      [0]
    )

    // mixpanel.track('Carry Me added to cart')
    fbq(context.currency, 'AddToCart', {
      content_name: 'Carry Me',
      content_category: 'Luggage',
      content_ids: [fbIDs[selectedColor]],
      content_type: 'product',
      value: !personalised ? 85 : 65,
      currency: context.language.replace('root', 'au').toUpperCase() + 'D',
    })

    trackAddToCart()
    context.openCart()
  }

  return (
    <>
      <SEO
        title={data.SEO.title.value}
        description={data.SEO.description.value}
        cannonical_url={data.SEO.cannonical_url.value}
        og_image={
          data.SEO?.TEST_banner_image?.value?.childImageSharp?.fixed?.srcWebp
        }
        productData={{
          name: 'Carry Me',
          imageSrc:
            'https://cdn.shopify.com/s/files/1/0047/1639/6613/products/03-FOR.jpg?v=1574928837',
          description: `The July Carry Me. The perfect bag to take when you don't need a bag at all. The right size for all the essentials, protected in our strong signature shell. With a detachable nylon shoulder strap, wear it however you'd like. Try for 100 days. 5 year warranty. `,
          price: parseInt((price?.match(/\d+/g) || []).join('')),
          sku: 'CME01-NAV',
          handle: 'carry-me',
        }}
        reviewData={{
          value: '5',
          count: '24',
        }}
      />
      <div
        style={{
          display: personaliseOpen ? 'block' : 'none',
          height: '100vh',
        }}
      >
        {personaliseOpen && (
          <PersonliseModal
            handleSave={(data) => {
              setPersonaliseOpen(false)
              setLabelData(data)
            }}
            handleClose={() => setPersonaliseOpen(false)}
            initialValues={labelData}
            selectedCaseColor={selectedColor}
            setSelectedCaseColor={setSelectedColor}
            selectedCase="Me-V2"
          />
        )}
      </div>
      <div style={{ display: personaliseOpen ? 'none' : 'block' }}>
        <Layout
          facebookCaseId={fbIDs[selectedColor]}
          location="product"
          context={context}
        >
          <CarryContainer>
            <LandingContainer>
              <GallerySection
                selectedColor={selectedColor}
                images={images[selectedColor]}
                videos={[]}
                campaign={true}
                defaultImage={1}
              />
              <SelectorSection
                name={title}
                price={price}
                reviewId={reviewId}
                freePersonalisation={freePersonalisation}
                oosData={oosData}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                outline={description}
                outlineSub={overview}
                details={specs}
                handleBefore={() => {}}
                handleAddToCart={handleAddToCart}
                isLuggage={true}
                noSet={true}
                noPersonalisation={true}
                setPersonaliseOpen={setPersonaliseOpen}
                labelData={labelData}
                personalised={personalised}
              />
            </LandingContainer>
            {/* {
              reviewId && (
                <Reviews productId={reviewId} />
              )
            } */}
          </CarryContainer>
        </Layout>
      </div>
    </>
  )
}
export const query = graphql`
  query {
    SEO: cockpitPage(
      cannonical_url: {
        value: { eq: "/travel-accessories/carry-me-bag-straps" }
      }
    ) {
      title {
        value
      }
      description {
        value
      }
      cannonical_url {
        value
      }
      keywords {
        value
      }
      category {
        value
      }

      TEST_banner_image {
        value {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }

    strap: allCockpitProducts(
      filter: {
        handle: { value: { eq: "/travel-accessories/carry-me-bag-straps" } }
      }
    ) {
      edges {
        node {
          handle {
            value
          }
          title {
            value
          }
          price {
            value
          }
          price_personalised {
            value
          }
          published {
            value
          }
          has_personalisation {
            value
          }
          free_personalisation {
            value
          }
          description {
            value
          }
          overview {
            value
          }
          specs {
            value
          }
          review_id {
            value
          }
          shopify_ids {
            value {
              data
            }
          }
          fb_ids {
            value {
              data
            }
          }
          lang
          oosDetails {
            value {
              data
            }
          }
        }
      }
    }

    Camp1: file(relativePath: { eq: "carry-me-v2/straps/1.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Camp2: file(relativePath: { eq: "carry-me-v2/straps/2.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Black2: file(relativePath: { eq: "carry-me-v2/straps/black/0.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black3: file(relativePath: { eq: "carry-me-v2/straps/black/1.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Clay2: file(relativePath: { eq: "carry-me-v2/straps/clay/0.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Clay3: file(relativePath: { eq: "carry-me-v2/straps/clay/1.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Forest2: file(relativePath: { eq: "carry-me-v2/straps/forest/0.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Forest3: file(relativePath: { eq: "carry-me-v2/straps/forest/1.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Navy2: file(relativePath: { eq: "carry-me-v2/straps/navy/0.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Navy3: file(relativePath: { eq: "carry-me-v2/straps/navy/1.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Sand2: file(relativePath: { eq: "carry-me-v2/straps/sand/0.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand3: file(relativePath: { eq: "carry-me-v2/straps/sand/1.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Shadow2: file(relativePath: { eq: "carry-me-v2/straps/shadow/0.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Shadow3: file(relativePath: { eq: "carry-me-v2/straps/shadow/1.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Yellow2: file(relativePath: { eq: "carry-me-v2/straps/yellow/0.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Yellow3: file(relativePath: { eq: "carry-me-v2/straps/yellow/1.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Blue2: file(relativePath: { eq: "carry-me-v2/straps/blue/0.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Blue3: file(relativePath: { eq: "carry-me-v2/straps/blue/1.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Magenta2: file(relativePath: { eq: "carry-me-v2/straps/magenta/0.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Magenta3: file(relativePath: { eq: "carry-me-v2/straps/magenta/1.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default CarryMe
